<template>
  <div>
    <div class="holder">
      <div class="nav-tab">
        <!-- <span>Profile</span>
        <span>Profile Picture</span>
        <span>Contact</span> -->
        <ul class="nav nav-tabs tabs-nav" id="myTab" role="tablist">
          <li class="nav-item">
            <a
              class="nav-link active"
              id="home-tab"
              data-toggle="tab"
              href="#home"
              role="tab"
              aria-controls="home"
              aria-selected="true"
              >Profile</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="profile-tab"
              data-toggle="tab"
              href="#profile"
              role="tab"
              aria-controls="profile"
              aria-selected="false"
              >Profile picture</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="contact-tab"
              data-toggle="tab"
              href="#contact"
              role="tab"
              aria-controls="contact"
              aria-selected="false"
              >Social Links</a
            >
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div
            class="tab-pane fade show active"
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <div class="profile-holder">
              <div class="body-holder">
                <div class="body-wrap">
                  <div class="container body-child">
                    <div class="card">
                      <div class="card-body my-card-body">
                        <div class="text-header">
                          <h1>WE WANT TO KNOW YOU BETTER</h1>
                        </div>
                        <form>
                          <div class="form-group row">
                            <input
                              type="text"
                              name="firstname"
                              class="form-control input-bar col-md-6 col-sm-12 edit"
                              id="firstname"
                              placeholder="| Your First Name"
                              v-model="user.firstname"
                            />
                          </div>
                          <div class="form-group row">
                            <input
                              type="text"
                              name="lastname"
                              class="form-control col-md-6 col-sm-12 input-bar"
                              id="lastname"
                              placeholder="| Your Last Name "
                              v-model="user.lastname"
                            />
                          </div>
                          <div class="form-group row">
                            <input
                              type="text"
                              name="othernames"
                              class="form-control col-md-6 col-sm-12 input-bar"
                              id="othername"
                              placeholder="| Any other name?"
                              v-model="user.othernames"
                            />
                          </div>
                          <div
                            class="form-group mb-3 row mobile-flex telephone-wrap"
                          >
                            <div class="input-group-prepend">
                              <button
                                class="btn btn-prefix col-sm-12 dropdown-toggle"
                                type="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                +234
                              </button>
                              <div class="dropdown-menu">
                                <a class="dropdown-item" href="#">+90</a>
                                <a class="dropdown-item" href="#">+44</a>
                                <a class="dropdown-item" href="#">+233</a>
                              </div>
                            </div>
                            <input
                              type="text"
                              name="phone"
                              id="phone"
                              class="form-control col-md-6 col-sm-12 input-bar"
                              aria-label="Text input with dropdown button"
                              v-model="user.phone"
                            />
                          </div>
                          <div class="form-group row">
                            <!-- v:bind:selected="country.id===user.countryid" -->
                            <select
                              v-model="user.gender"
                              name="gender"
                              class="form-control col-md-6 col-sm-12 input-bar"
                              id="exampleFormControlSelect2"
                            >
                              <option value="" disabled>Gender</option>
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                            </select>
                          </div>
                          <div class="form-group row">
                            <input
                              type="text"
                              name="dob"
                              class="form-control col-md-6 col-sm-12 input-bar"
                              id="othername"
                              placeholder="Date of birth"
                              v-model="user.dob"
                              onfocus="(this.type='date')"
                            />
                          </div>
                          <div class="form-group row">
                            <input
                              type="text"
                              name="address"
                              class="form-control col-md-6 col-sm-12 input-bar"
                              id="address"
                              placeholder="| Address"
                              v-model="user.address"
                            />
                          </div>

                          <div class="form-group row">
                            <!-- v:bind:selected="country.id===user.countryid" -->
                            <select
                              @change="getStatesFromCountry($event)"
                              v-model="user.countryid"
                              name="country"
                              class="form-control col-md-6 col-sm-12 input-bar"
                              id="exampleFormControlSelect2"
                            >
                              <option value="" disabled>Select country</option>

                              <option
                                v-for="(country, index) in getCountries"
                                :key="index"
                                v-bind:value="country.id"
                                >{{ country.name }}</option
                              >
                            </select>
                          </div>
                          <div class="form-group row">
                            <select
                              name="state"
                              v-model="user.stateid"
                              class="form-control col-md-6 col-sm-12 input-bar"
                              id="exampleFormControlSelect2"
                            >
                              <option value="" disabled>Select state</option>
                              <option
                                v-for="(state, index) in getStates"
                                :key="index"
                                v-bind:value="state.id"
                                >{{ state.name }}</option
                              >
                            </select>
                          </div>
                          <!-- <div class="form-group row">
                            <button 
                              @click.prevent="enableEdit()"
                              class="btn setup-btn btn-block col-md-6 col-sm-12"
                            >
                              EDIT
                              <i
                                class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                                v-if="loader"
                              ></i>
                            </button>
                          </div> -->
                          <div class="form-group row">
                            <button
                              @click.prevent="profileSetup()"
                              class="btn setup-btn btn-block col-md-6 col-sm-12"
                            >
                              CONTINUE
                              <i
                                class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                                v-if="loader"
                              ></i>
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <div class="profile-holder">
              <div class="body-holder">
                <div class="body-wrap">
                  <div class="container body-child">
                    <div class="card">
                      <div class="card-body my-card-body">
                        <div class="profile-holder">
                          <div class="profile-wrapper">
                            <img
                              class="online-avatar"
                              v-if="user.passportfile != ''"
                              v-bind:src="user.passportfile"
                              alt=""
                            />
                            <img
                              class="img-avatar"
                              v-else
                              src="../assets/images/big-user.svg"
                              alt=""
                            />
                          </div>
                          <small>{{ fileName }}</small>
                          <div class="uploadChange-photo">
                            <p v-if="user.passportfile === ''">
                              <span @click="$refs.file.click()" class=""
                                >Upload Photo</span
                              >
                              <input
                                @change="getFileName($event)"
                                class="d-none"
                                ref="file"
                                type="file"
                              />
                            </p>
                            <p v-else>
                              <span @click="$refs.file.click()" class=""
                                >Change Photo</span
                              >
                              <input
                                @change="getFileName($event)"
                                class="d-none"
                                ref="file"
                                type="file"
                              />
                            </p>
                          </div>
                          <div class="file-check-holder">
                            <span v-if="fileSizeType && fileSizeInMb <= 1">
                              <img
                                src="../assets/images/blue-good.svg"
                                alt=""
                              />
                              <p>{{ fileSizeInMb }}MB</p>
                            </span>
                            <span v-if="fileSizeType && fileSizeInMb > 5">
                              <img
                                src="../assets/images/red-wrong.svg"
                                alt=""
                              />
                              <p>{{ fileSizeInMb }}MB</p>
                              <p>Maximun size 5MB</p>
                            </span>
                          </div>
                          <div class="form-group pt-4 row">
                            <button
                              @click.prevent="changeProfileImage()"
                              class="btn setup-btn btn-block col-6"
                            >
                              Save
                              <i
                                class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                                v-if="loader"
                              ></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="contact"
            role="tabpanel"
            aria-labelledby="contact-tab"
          >
            <div class="profile-holder">
              <div class="body-holder">
                <div class="body-wrap">
                  <div class="container body-child">
                    <div class="card">
                      <div class="card-body my-card-body">
                        <div class="text-header">
                          <h1>SOCIAL MEDIA HANDLES</h1>
                        </div>
                        <form>
                          <!-- <div
                            class="form-group mb-3 row mobile-flex telephone-wrap"
                          >
                            <div class="input-group-prepend side-group side-select">
                              <select
                                id="inputState"
                                class="form-control col-sm-12"
                              >
                                <option selected>+234</option>
                                <option>+99</option>
                              </select>
                              
                            </div>
                            <input
                              type="text"
                              class="form-control col-md-6 col-sm-12 input-bar"
                              aria-label="Text input with dropdown button"
                            />
                          </div> -->
                          <div class="form-group row">
                            <div
                              class="input-group col-md-6 col-sm-12 lrpadding"
                            >
                              <div class="input-group-prepend">
                                <div class="input-group-text side-group">
                                  <img
                                    src="../assets/images/whatsapp-icon.svg"
                                    alt=""
                                  />
                                </div>
                              </div>
                              <input
                                type="text"
                                class="form-control input-bar"
                                id="whatsapp"
                                name="whatsapp"
                                placeholder="WhatsApp number"
                                v-model="user.whatsapp"
                              />
                            </div>
                          </div>
                          <div class="form-group row">
                            <div
                              class="input-group col-md-6 col-sm-12 lrpadding"
                            >
                              <div class="input-group-prepend">
                                <div class="input-group-text side-group">
                                  <img
                                    src="../assets/images/website-icon.svg"
                                    alt=""
                                  />
                                </div>
                              </div>
                              <input
                                type="text"
                                class="form-control input-bar"
                                id="website"
                                name="website"
                                placeholder="Website"
                                v-model="user.website"
                              />
                            </div>
                          </div>
                          <div class="form-group row">
                            <div
                              class="input-group col-md-6 col-sm-12 lrpadding"
                            >
                              <div class="input-group-prepend">
                                <div class="input-group-text side-group">
                                  <img
                                    src="../assets/images/facebook-icon.svg"
                                    alt=""
                                  />
                                </div>
                              </div>
                              <input
                                type="text"
                                class="form-control input-bar"
                                id="facebook"
                                name="facebook"
                                placeholder="Facebook username"
                                v-model="user.facebook"
                              />
                            </div>
                          </div>
                          <div class="form-group row">
                            <div class="input-group col-md-6 col-sm- lrpadding">
                              <div class="input-group-prepend">
                                <div class="input-group-text side-group">
                                  <img
                                    src="../assets/images/insta-icon.svg"
                                    alt=""
                                  />
                                </div>
                              </div>
                              <input
                                type="text"
                                class="form-control input-bar"
                                id="instagram"
                                name="instagram"
                                placeholder="Instagram handle"
                                v-model="user.instagram"
                              />
                            </div>
                          </div>
                          <div class="form-group row">
                            <div
                              class="input-group col-md-6 col-sm-12 lrpadding"
                            >
                              <div class="input-group-prepend">
                                <div class="input-group-text side-group">
                                  <img
                                    src="../assets/images/twitter-icon.svg"
                                    alt=""
                                  />
                                </div>
                              </div>
                              <input
                                type="text"
                                class="form-control input-bar"
                                id="twitter"
                                name="twitter"
                                placeholder="Twitter handle"
                                v-model="user.twitter"
                              />
                            </div>
                          </div>

                          <div class="form-group row">
                            <button
                              @click.prevent="socialLinkSetup()"
                              class="btn setup-btn btn-block col-md-6 col-sm-12"
                            >
                              SAVE
                              <i
                                class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                                v-if="loader"
                              ></i>
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Service } from "../store/service";
const Api = new Service();
export default {
  name: "Profile",

  components: {},
  data() {
    return {
      loader: false,
      id: "",
      errors: [],
      uploadFile: "",
      user: "",
      fileName: "",
      selectedCountry: "",
      selectedState: "",
      selectedCity: "",
      dob: "",
      gender: "",
      getCountries: "",
      getStates: "",
      cities: "",
      file: "",
      filePath: "",
      fileSizeType: false,
      fileSizeInMb: "",
      id: ""
    };
  },
  mounted() {
    this.id = JSON.parse(localStorage.getItem("shelta-user")).id;
    this.getUserProfile(this.id);

    Api.getRequest(`fetchallcountries`)
      .then(res => {
        this.$store.commit("setApiWarning", "Loading countries...");
        console.log(res);
        if (res.data.success) {
          this.getCountries = res.data.countries;
          this.getStatesFromCountry();
          this.$store.commit("setApiSuccess", "Countries loaded");
          this.$store.commit("setApiWarning", "");
        } else if (res.data.error) {
          return this.$store.commit("setApiFailed", res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  },
  methods: {
    // previewImage: function(event) {
    //   this.file = this.$refs.file.files[0];
    //   this.filePath = this.$refs.file.value;
    //   // const formData = new FormData();
    //   // formData.append("file", this.file);
    //   console.log("preview", event.target.value);
    // },
    addEmergencyInfo: function() {
      this.loader = true;
      this.errors = [];
      if (this.fullname === "") {
        this.errors.push("Full name is required!");
        this.loader = false;
      }
      if (this.phone === "") {
        this.errors.push("Phone number is required!");
        this.loader = false;
      }
      if (this.address === "") {
        this.errors.push("Address is required!");
        this.loader = false;
      }
      if (this.relationship === "") {
        this.errors.push("Relationship is required!");
        this.loader = false;
      } else {
        Api.postRequest(`addemergencies`, {
          id: JSON.parse(localStorage.getItem("shelta-user")).id,
          fullname: this.fullname,
          phone: this.phone,
          address: this.address,
          relationship: this.relationship
        }).then(res => {
          if (res.data.success) {
            this.loader = false;
            console.log("success res", res);
            localStorage.setItem("shelta-user", JSON.stringify(res.data.user));
            return this.$store.commit("setApiSuccess", res.data.success);
          } else if (res.data.error) {
            this.loader = false;
            return this.$store.commit("setApiFailed", res.data.error);
          }
        });
      }
    },
    getFileName: function() {
      this.file = this.$refs.file.files[0];
      this.fileName = this.$refs.file.files[0].name;
      console.log("File name", this.fileName);
    },
    changeProfileImage: function() {
      this.loader = true;
      this.file = this.$refs.file.files[0];
      console.log("file to upload", this.file);
      if (!this.file) {
        this.loader = false;
        return this.$store.commit("setApiFailed", "Nothing was selected!");
      }
      if (
        this.file.type === "image/jpeg" ||
        this.file.type === "image/jpg" ||
        this.file.type === "image/png" ||
        this.file.type === "image/gif"
      ) {
        this.loader2 = true;
      } else {
        this.loader2 = false;
        return this.$store.commit(
          "setApiFailed",
          "Please select an image file"
        );
      }
      const formData = new FormData();
      const sizeInMB = (this.file.size / (1024 * 1024)).toFixed(3);
      this.fileSizeInMb = sizeInMB;
      console.log("File Size", sizeInMB);
      console.log("form data", formData);
      formData.append("id", this.user.id);
      formData.append("file", this.file);

      if (sizeInMB > 5) {
        this.fileSizeType = true;
        this.loader = false;
        return this.$store.commit(
          "setApiFailed",
          "File size too large, should be less than 5MB"
        );
      } else {
        Api.postRequest(`uploaduserphoto`, formData)
          .then(res => {
            console.log("File upload", res);
            this.loader = false;
            if (res.data.error) {
              console.log("error while uploading:");
              this.fileSizeType = true;
              return this.$store.commit("setApiFailed", "An error occured");
            } else {
              this.filePath = res.data.url;
              this.getUserProfile(this.id);
              this.fileSizeType = true;
              this.$store.commit("setApiSuccess", "profile picture updated");
              console.log("Uploaded Sucessfully");
              console.log("Success file size", this.fileSizeInMb);
            }
          })
          .catch(err => {
            this.loader = false;
            console.log(err);
          });
      }
    },
    enableEdit: function() {
      const input = document.getElementsByClassName("edit");
      if (input[0].disabled === true) {
        input[0].disabled = false;
        console.log("input check true:", input);
      } else {
        input[0].disabled = true;
      }
    },
    getUserProfile: function() {
      Api.getRequest(`getuserbyid/${this.id}`).then(res => {
        // console.log("User profile", res);
        if (res.status == 401) {
          window.localStorage.clear();
          return window.location.href = "https://myshelta.com/login";
        }
        this.user = res.data.user;
        localStorage.setItem("shelta-user", JSON.stringify(res.data.user));
        console.log("countryid:", this.user.countryid);
      });
    },
    getStatesFromCountry: function() {
      Api.getRequest(`fetchstatesbycountryid/${this.user.countryid}`)
        .then(res => {
          this.$store.commit("setApiWarning", "Loading states...");
          console.log("States from country", res);
          if (res.data.success) {
            this.getStates = res.data.states;
            this.$store.commit("setApiSuccess", "States loaded");
            this.$store.commit("setApiWarning", "");
          } else if (res.data.error) {
            return this.$store.commit("setApiFailed", res.data.error);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },

    socialLinkSetup: function() {
      this.loader = true;
      this.errors = [];
      if (this.user.whatsapp === "") {
        this.errors.push("WhatsApp number is required!");
        this.loader = false;
      } else {
        Api.postRequest(`updatesocialmedia`, {
          id: JSON.parse(localStorage.getItem("shelta-user")).id,
          whatsapp: this.user.whatsapp,
          website: this.user.website,
          facebook: this.user.facebook,
          instagram: this.user.instagram,
          twitter: this.user.twitter
        }).then(res => {
          if (res.data.success) {
            this.loader = false;
            console.log("success res", res);
            localStorage.setItem("shelta-user", JSON.stringify(res.data.user));
            return this.$store.commit("setApiSuccess", res.data.success);
          } else if (res.data.error) {
            this.loader = false;
            return this.$store.commit("setApiFailed", res.data.error);
          }
        });
      }
    },

    profileSetup: function() {
      console.log("Hellow profile", this.dob);
      this.loader = true;
      this.errors = [];
      if (this.firstname === "") {
        this.errors.push("First name is required!");
        this.loader = false;
      }
      if (this.lastname === "") {
        this.errors.push("Last name is required!");
        this.loader = false;
      }
      if (this.othernames === "") {
        this.errors.push("Other name is required!");
        this.loader = false;
      }
      if (this.address === "") {
        this.errors.push("Address is required!");
        this.loader = false;
      }
      if (this.user.countryid === "") {
        this.errors.push("Please select a country");
        this.loader = false;
      }
      if (this.user.stateid === "") {
        this.errors.push("Please select a state");
        this.loader = false;
      }
      if (this.dob === "") {
        this.errors.push("Date of birth required");
        this.loader = false;
      }
      if (this.gender === "") {
        this.errors.push("Gender is required");
        this.loader = false;
      }
      if (this.doc === "") {
        this.errors.push("Date of birth is required");
        this.loader = false;
      } else if (this.phone === "") {
        this.errors.push("Phone is required!");
        this.loader = false;
      } else {
        Api.postRequest(`updateuser`, {
          id: JSON.parse(localStorage.getItem("shelta-user")).id,
          firstname: this.user.firstname,
          lastname: this.user.lastname,
          address: this.user.address,
          dob: this.user.dob,
          othernames: this.user.othernames,
          countryid: this.user.countryid,
          stateid: this.user.stateid,
          // cityid: this.selectedCity.id,
          phone: this.user.phone,
          gender: this.gender
        }).then(res => {
          if (res.data.success) {
            this.loader = false;
            console.log("success res", res);
            localStorage.setItem("shelta-user", JSON.stringify(res.data.user));
            return this.$store.commit("setApiSuccess", res.data.success);
          } else if (res.data.error) {
            this.loader = false;
            return this.$store.commit("setApiFailed", res.data.error);
          }
        });
      }
    },
    // getCountryId: function(event) {
    //   this.$store.commit("setApiWarning", "Loading cities...");
    //   Api.getRequest(`fetchstatesbycountryid/${this.selectedCountry.id}`).then(
    //     (res) => {
    //       console.log("Sates:", res);
    //       this.getStates = res.data.states;
    //       this.$store.commit("setApiSuccess", "States loaded");
    //       this.$store.commit("setApiWarning", "");
    //     }
    //   );
    // },
    onUploadProfile: function(event) {
      const file = event.target.files[0];
      console.log("File:", file);
      this.file = file;
      const files = event.srcElement.files[0];
      // this.staffForm.get('upload').setValue(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        this.image = reader.result;
        // console.log("Image", this.image);
        this.cdf.detectChanges();
      };
      reader.readAsDataURL(files);
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$fontFamily: url("https://fonts.googleapis.com/css2?family=Gotham:wght@100&display=swap");
$fontFamily2: url("https://fonts.googleapis.com/css2?family=Gotham-Ultra:wght@100&display=swap");

.tabs-nav {
  background-color: $secondary;
  padding-left: 7rem;
}
.nav-link {
  color: #000000;
  font-family: $fontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 30px;
}
.nav-link.active {
  border-bottom: 1px solid $primary !important;
  color: #856e6e;
  border-left: none;
  border-right: none;
}

::-webkit-input-placeholder {
  /* Edge */
  font-family: $fontFamily;
  font-style: italic;
  font-weight: 300;
  font-size: 13px;
  line-height: 13px;
  color: rgba(0, 0, 0, 0.5);
  text-decoration-line: underline;
}

:-ms-input-placeholder {
  /* Internet Explorer */
  font-family: $fontFamily;
  font-style: italic;
  font-weight: 300;
  font-size: 13px;
  line-height: 13px;
  color: rgba(0, 0, 0, 0.5);
  text-decoration-line: underline;
}

::placeholder {
  font-family: $fontFamily;
  font-style: italic;
  font-weight: 300;
  font-size: 13px;
  line-height: 13px;
  color: rgba(0, 0, 0, 0.5);
  text-decoration-line: underline;
}

.profile-holder {
  // background: #ffffff;
  margin: 2rem 6rem 4rem 6rem;
}
.profile-body {
}
.form-group {
  justify-content: center !important;
}

.body-child {
  // width: 35.46rem;
  min-height: auto;
  margin: auto;
  // display: none;
}
.my-card-body {
  box-sizing: border-box;
  padding-bottom: 6rem;
}
.text-header {
  padding-top: 3rem;
}
.text-header > h1 {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 40px;
  letter-spacing: -0.05em;
  text-decoration-line: underline;
  text-transform: uppercase;

  color: #000000;
}

.lrpadding {
  padding-right: 0;
  padding-left: 0;
}
.input-bar {
  background: #ffffff;
  height: 44px;
  border: 0.5px solid #445db6;
  // border: 0.2px solid #a2b0e3;
  box-sizing: border-box;
  box-shadow: none;
  border-radius: 0px;
}
.select-bar {
  height: 50px !important;
}
.btn-prefix {
  color: #495057;
  box-shadow: none !important;
  border-color: #445db6 !important;
  border-radius: 0px;
}
.btn-prefix:hover {
  background: $primary !important;
  color: #ffffff;
}
.setup-btn:hover {
  opacity: 0.6;
}
.setup-btn:active {
  transform: translateY(4px);
}
.setup-btn {
  background: $primary;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  color: #ffffff;
  height: 45px;
}

.radio-div {
  background: #ffffff;
  height: 44px;
  border: 0.5px solid #445db6;
  box-sizing: border-box;
  box-shadow: none;
  border-radius: 5px;
}

.radio-btn {
  padding-top: 0.7rem;
  display: flex;
  justify-content: space-evenly;
}

.errorHeader {
  font-family: $fontFamily;
  font-weight: normal;
  font-size: 17px;
  list-style: none;
  text-align: center;
  margin-bottom: 0;
  // margin-left: 1em;
}

.error-ul {
  padding-inline-start: 0;
}

.btn-loader {
  padding: 0 !important;
  font-size: 17px !important;
  margin-left: 2rem !important;
}

// Welcome page that was moved to his components
.welcome-holder {
  padding-top: 4rem;
  padding-bottom: 3rem;
  display: none;
}

.welcome-child {
  background: $primary;
  width: 441.03px;
  height: 398.87px;
  margin: 0 auto;
}
.close-welcome-div {
  display: flex;
  justify-content: flex-end;
  position: relative;
}
.close-welcome-div > small {
  position: absolute;
  top: 1rem;
}
.welcome-body {
  padding-top: 3rem;
}
.close-welcome-icon::before {
  color: #ffffff;
  font-size: 1rem;
}
.welcome-body > p:first-child {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 40px;
  align-items: center;
  text-align: center;
  letter-spacing: -0.05em;
  color: #ffffff;
  padding-bottom: 1rem;
}
.welcome-body > p:nth-child(3) {
  font-family: Gotham Ultra;
  font-style: italic;
  font-weight: 800;
  font-size: 35px;
  line-height: 40px;
  align-items: center;
  text-align: center;
  letter-spacing: -0.05em;
  color: #ffffff;
  padding-top: 1rem;
}
// Welcome page ends

// Profile image section begins
.profile-wrapper {
  background: $primary;
  width: 174.34px;
  height: 174.34px;
  border-radius: 50%;
  margin: auto;
}
.online-avatar {
  // padding-top: 2rem;
  width: 174.34px;
  height: 174.34px;
  border-radius: 100px;
  object-fit: cover;
  border: 0.5px solid #8177ce;
}
.img-avatar {
  padding-top: 2rem;
}

.uploadChange-photo {
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  justify-content: center;
}
.uploadChange-photo > p:first-child {
  background-color: $secondary;
  border: 0.5px solid #e5e5e5;
  box-sizing: border-box;
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 30px;
  align-items: center;
  text-align: center;
  letter-spacing: -0.05em;
  color: #000000;
  width: 97.13px;
  height: 30.9px;
  cursor: pointer;
}
.uploadChange-photo > p:nth-child(2) {
  background-color: $primary;
  border: 0.3px solid #e5e5e5;
  box-sizing: border-box;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 30px;
  align-items: center;
  text-align: center;
  letter-spacing: -0.05em;
  color: $secondary;
  width: 97.13px;
  height: 30.9px;
  cursor: pointer;
}

.file-check-holder {
  display: flex;
  justify-content: center;
}
// Profile iamge section ends

// Contact section begins
.side-group {
  background-color: $secondary;
  border-radius: 0;
  padding: 0.3rem 0.73rem !important;
  border: 1px solid $primary;
}

.side-select {
  height: calc(1.5em + 0.75rem + 8px);
  border: 1px solid #445db6;
}
// Contact section ends
.errorHeader {
  font-family: $fontFamily;
  font-weight: normal;
  font-size: 17px;
  list-style: none;
  text-align: center;
  margin-bottom: 0;
  // margin-left: 1em;
}
.errorMessage {
  font-family: $fontFamily;
  font-weight: normal;
  color: #e10000;
  font-size: 14px;
  list-style: none;
  text-align: center;
  padding-inline-start: 0;
}
.error-ul {
  padding-inline-start: 0;
}

::-webkit-input-placeholder {
  /* Edge */
  font-family: $fontFamily;
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  line-height: 25px;
  color: rgba(0, 0, 0, 0.5);
  text-decoration-line: underline;
}
.gender-label {
  font-family: $fontFamily;
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  line-height: 25px;
  color: rgba(0, 0, 0, 0.5);
}
:-ms-input-placeholder {
  /* Internet Explorer */
  font-family: $fontFamily;
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  line-height: 25px;
  color: rgba(0, 0, 0, 0.5);
  // text-decoration-line: underline;
}

::placeholder {
  font-family: $fontFamily;
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  line-height: 25px;
  color: rgba(0, 0, 0, 0.5);
  // text-decoration-line: underline;
}

@media only screen and (max-width: 599px) {
  .profile-holder {
    margin: 2rem 0 4rem 0;
  }
  .mobile-flex {
    flex-wrap: nowrap !important;
  }
  .tabs-nav {
    padding-left: 0;
    padding-left: 0;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .nav-link {
    font-size: 10px;
    line-height: 40px;
    white-space: pre;
  }
}

@media screen and (min-width: 1200px) and (max-height: 640px) and (-webkit-device-pixel-ratio: 1) {
  .lrpadding {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
  .body-child {
    // max-width: 35.46rem !important;
  }
  .telephone-wrap {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .mobile-flex {
    flex-wrap: wrap !important;
  }
}
</style>
